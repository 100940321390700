import { apiInstance } from '../../middlewares/utils/axios';
import {
  FETCH_DATA_INITIATE,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILED,
  FETCH_DATA_CLEAR,
} from '../actionType';
import { getToken } from '../../middlewares/utils/userHelperFunctions';

export const clearData = (payload) => {
  return {
    type: FETCH_DATA_CLEAR,
    key: payload.key,
    page: payload.page,
  };
};

let fetchDataConfig = {
  FETCH_DATA_CUSTOMER: {
    url: `admin/customers/list`,
  },
  FETCH_DATA_PARTNER: {
    url: `admin/partners/list`
  },
  FETCH_PARTNER: {
    url: `admin/partner/get`
  }
  
};

export const fetchData = (payload) => {
  let url = fetchDataConfig[payload.key].url;
  let headers = {
    'Content-Type': 'application/json',
  };


  if (payload.headers) {
    headers = { ...payload.headers, ...headers };
  }
  return async(dispatch) => {


    dispatch(
      fetchDataInitiate({
        key: payload.key,
        page: payload.req && payload.req.page ? payload.req.page : false,
        subKey: payload.subKey
      })
    );
    if (payload.token) {
      const access_token =  await getToken();
      headers['Authorization'] = `Bearer ${access_token}`;
    } 
    if (payload.method === 'POST') {
      apiInstance
        .post(
          url,
          {
            ...payload.req,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          dispatch(
            fetchDataSuccess({
              key: payload.key,
              subKey: payload.subKey,
              data: response.data,
            })
          );
        })
        .catch((error) => {
          dispatch(
            fetchDataFailure({
              key: payload.key,
              error,
            })
          );
        });
    } else if (payload.method === 'GET') {
      let qp = '';

      if (payload.req) {
        Object.keys(payload.req).map((key) => {
          if (qp.length > 0) {
            qp += '&';
          }
          qp += `${key}=${payload.req[key]}`;
          return null;
        });
        url = `${url}?${qp}`;
      }

      apiInstance
        .get(url, {
          headers: headers,
        })
        .then((response) => {
          dispatch(
            fetchDataSuccess({
              key: payload.key,
              subKey: payload.subKey,
              data: response.data,
            })
          );
        })

        .catch((error) => {
          dispatch(
            fetchDataFailure({
              key: payload.key,
              subKey: payload.subKey,
              error,
            })
          );
        });
    }
  };
};

const fetchDataInitiate = (data) => {
  return {
    type: FETCH_DATA_INITIATE,
    data,
  };
};

const fetchDataSuccess = (data) => {
  return {
    type: FETCH_DATA_SUCCESS,
    data: data,
  };
};

const fetchDataFailure = (err) => {
  return {
    type: FETCH_DATA_FAILED,
    data: err,
  };
};
