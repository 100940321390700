import React, { useEffect, useContext, useState } from "react";
import Router from "./router/Index";
import { Provider } from 'react-redux';
import NoSsr from "@material-ui/core/NoSsr";
import Box from "@material-ui/core/Box";
import store from './store';
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./constants/theme/Index";
import i18n from "i18n-js";
import { useLanguage } from "./middlewares/hooks/UseLanguage";
import constants from "./constants/language/constants.json";
import { FirebaseContext } from "./middlewares/hooks/useFirebase";
import { SnackbarProvider } from 'notistack';
i18n.translations = constants;
i18n.fallbacks = true;

function App() {
  const [language] = useLanguage();
  const [user, setUser] = useState(null);
  if (language) {
    i18n.locale = language;
  }

  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    firebase?.auth()?.onAuthStateChanged(() => {
      if(user) {
        setUser('LOGGED')
      } else {
       setUser('NOT_LOGGEDD')
      }
    });

  });


  return (
    <NoSsr>
       <Provider store={store}>
      <ThemeProvider theme={theme}>
      <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
    }}
      maxSnack={1}>
        <Box
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="flex-start"
         color="text.primary" bgcolor="secondary.main">
           {user && <Router></Router>}
        </Box>
        </SnackbarProvider>
      </ThemeProvider>
      </Provider>
    </NoSsr>
  );
}

export default App;
