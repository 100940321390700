import React from "react";
import firebase from "firebase/app";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";

import DashBoardLayout from "../containers";
import * as AuthenticationRouter from "../containers/auth";

// Components for Transition and Error Management
import CircularProgress from "../components/progress/CircularProgress";

// Middleware to Auth State
const requireLogin = async (to, from, next) => {
  if (to.meta.auth) {
    firebase?.auth()?.onAuthStateChanged((user) => {
      if (user) {
        next();
      } else {
        next.redirect("/login");
      }

      // firebase.auth().signOut()
    });
    // next.redirect("/login");
  } else {
    next.redirect("/");
  }
};
const requireNoAuthentication = async (to, from, next) => {
  if (to.meta.noAuth) {
    firebase?.auth()?.onAuthStateChanged((user) => {
      if (user) {
        next.redirect("/");
      } else {
        next();
      }

      // firebase.auth().signOut()
    });
  }
  // next.redirect("/login");
};

const App = () => (
  <BrowserRouter>
    <GuardProvider guards={[requireLogin]} loading={CircularProgress}>
      <Switch>
        <GuardedRoute
          meta={{
            auth: true,
          }}
          path="/"
          exact
        >
          <Redirect to="/dashboard/insights" />
        </GuardedRoute>

        <GuardedRoute
          meta={{
            auth: true,
          }}
          path="/dashboard/:screen"
          
          component={DashBoardLayout}
        />

        {/* <GuardedRoute path="/login" exact component={DashBoardLayout} /> */}
      </Switch>
    </GuardProvider>
    <GuardProvider
      guards={[requireNoAuthentication]}
      loading={CircularProgress}
    >
      <Switch>
        <GuardedRoute
          meta={{ noAuth: true }}
          path="/login"
          exact
          component={AuthenticationRouter.Login}
        />
        {/* <GuardedRoute path="/login" exact component={DashBoardLayout} /> */}
      </Switch>
    </GuardProvider>
  </BrowserRouter>
);

export default App;
