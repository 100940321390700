import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import AsyncStorageReducer from './reducers/SaveAsync';
import ExecuteData from "./reducers/ExecuteData";
import FetchData from './reducers/FetchData';
import { LOGOUT } from "./actionType";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const reducer = combineReducers({AsyncStorageReducer, ExecuteData, FetchData});
const rootReducer = (state, action) => {   
    // Clear all data in redux store to initial.
    if(action.type === LOGOUT) {
      state = {};
    }
   
    
    return reducer(state, action);
 };

const store = createStoreWithMiddleware(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;