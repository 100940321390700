import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
export default function ButtonCustom(props) {
    const useStyles = makeStyles((theme) => ({
button: {
    borderRadius: theme.spacing(1),
    width: props.width? props.width : theme.spacing(25)
}

    }));
    const classes = useStyles();
  return <Button className={classes.button} {...props} />;
}
