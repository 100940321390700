import React from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n-js";
import moment from "moment";
import at from "v-at";
import {
  FETCH_DATA_CUSTOMER,
  IS_INITIATED,
  GET,
  IS_DONE,
  POST,
  REQUEST,
} from "../../constants/types/types";
import { fetchData } from "../../store/actions/FetchData";
import Box from "@material-ui/core/Box";
import Table from "../../components/table";
import Button from "../../components/button/Button";
import { Grid } from "@material-ui/core";
import FullScreenDialog from "../../components/dialog/FullScreenModal";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import { executeData } from "../../store/actions/ExecuteData";
const header = [
  {
    id: "displayName",
    numeric: false,
    disablePadding: false,
    label: "Name",
    type: "string",
  },
  {
    id: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Phone",
    type: "string",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    type: "string",
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
    type: "chip",
  },
  {
    id: "region",
    numeric: false,
    disablePadding: false,
    label: "Region",
    type: "chip",
  },
  {
    id: "creationTime",
    numeric: true,
    disablePadding: false,
    label: "Join Date",
    type: "string",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    type: "button",
  },
];

const Customer = () => {
  const dispatch = useDispatch();
  const fetchDataResponse = useSelector((state) => {
    return state.FetchData;
  });
  // const executeDataResponse = useSelector((state) => {
  //   return state.ExecuteData;
  // });
  const [loader, setLoader] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [usersAndToken, setUsersAndToken] = React.useState([]);
  const [notificationMessage, setNotificationMessage] = React.useState(null);
  const [notificationTitle, setNotificationTitle] = React.useState(null);

  React.useEffect(() => {
    // Start Fetching Customers List
    if (!loader) {
      if (!at(fetchDataResponse, `${FETCH_DATA_CUSTOMER}.${IS_INITIATED}`)) {
        dispatch(
          fetchData({
            key: FETCH_DATA_CUSTOMER,
            method: GET,
            token: true,
          })
        );
        setLoader(true);
      }
    }
    // Save Fetched Customers List
    if (
      loader ||
      (data.length === 0 &&
        at(fetchDataResponse, `${FETCH_DATA_CUSTOMER}.data`)?.length > 0)
    ) {
      if (at(fetchDataResponse, `${FETCH_DATA_CUSTOMER}.${IS_DONE}`)) {
        const customersArrayTemp = [];
        for (const customer of at(
          fetchDataResponse,
          `${FETCH_DATA_CUSTOMER}.data`
        )) {
          const customerObject = customer;
          customerObject.phoneNumber = at(
            customer,
            "userInfo.providerData.0.phoneNumber"
          );
          customerObject.creationTime = moment(
            at(customer, "userInfo.metadata.creationTime")
          ).format("MM/DD/YYYY");
          customerObject.city = [];
          customerObject.region = [];
          at(customer, "address")?.map((addr) => {
            customerObject.city.push(addr?.address.city);
            customerObject.region.push(
              addr?.address.region ? addr?.address.region : addr?.address.state
            );
            return null;
          });
          customersArrayTemp.push(customerObject);
        }
        setData(customersArrayTemp);
        setLoader(false);
      }
    }
  }, [loader, fetchDataResponse, dispatch, data.length]);

  const handlePushNotification = () => {
    try {
      const usersAndToken = [];
      data.map((each) => {
        usersAndToken.push({
          displayName: each.displayName,
          notificationToken: each.notificationToken,
        });
        return null;
      });
      setUsersAndToken(usersAndToken);
      setModal(true);
      console.log(data);
    } catch (error) {}
  };

  const handlePushNotificationSent = () => {
    setModal(false);
    dispatch(
      executeData({
        key: "PUSHNOTIFICATION",
        token: true,
        method: POST,
        req: {
          userData: [
            {
              displayName: "Tony",
              notificationToken: {
                type: "expo",
                data: "ExponentPushToken[DJLEkWEFzPYLhnx8upGVyD]",
              },
            },
          ],
          body: notificationMessage,
          title: notificationTitle,
        },
      })
    );
  };

  const handleTableRowClick = (id) => {};
  return (
    <Box>
      <Box id="top_action_bar" marginTop={1} marginY={1} marginX={2}>
        <Grid
          container
          direction="row-reverse"
          alignItems="center"
          justify="flex-start"
        >
          <Grid item xs={2}>
            <Button
              variant="outlined"
              width="100%"
              onClick={handlePushNotification}
              color="primary"
              disableElevation
            >
              {i18n.t("sent_notification")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <FullScreenDialog
        modal={modal}
        content={
          <Box m={2} flexDirection={"column"}>
            <Box marginY={2}>
              {/* <TextField
                label={i18n.t("notification_label")}
                multiline
                style={{ width: "80%" }}
                disabled
                variant="outlined"
              /> */}
              {usersAndToken?.map((item) => (
                <Chip
                  color="primary"
                  label={item?.displayName}
                  variant="outlined"
                />
              ))}
            </Box>
            <Box marginY={2}>
              <TextField
                style={{ width: "80%" }}
                label={i18n.t("notification_title")}
                multiline
                rows={1}
                onChange={(e) => {
                  setNotificationTitle(e?.target?.value);
                }}
                variant="outlined"
              />
            </Box>
            <Box marginY={2}>
              <TextField
                style={{ width: "80%" }}
                label={i18n.t("notification_placeholder")}
                multiline
                rows={4}
                onChange={(e) => {
                  setNotificationMessage(e?.target?.value);
                }}
                variant="outlined"
              />
            </Box>
            <Box marginY={2}>
              <Button
                variant="outlined"
                onClick={handlePushNotificationSent}
                color="primary"
                disableElevation
              >
                {i18n.t("sent")}
              </Button>
            </Box>
          </Box>
        }
        setModal={(value) => setModal(value)}
        title={i18n.t("sent_notification")}
        right={false}
      />

      <Table
        rows={data}
        onClick={(id) => handleTableRowClick(id)}
        title={i18n.t("customers")}
        loader={loader}
        header={header}
      />
    </Box>
  );
};

export default Customer;
