import { apiInstance } from '../../middlewares/utils/axios';
import {
  EXECUTE_DATA_INITIATE,
  EXECUTE_DATA_SUCCESS,
  EXECUTE_DATA_FAILED,
  EXECUTE_DATA_CLEAR,
} from '../actionType';

import { getToken } from '../../middlewares/utils/userHelperFunctions';



export const clearDataExecuteData = (payload) => {
  return {
    type: EXECUTE_DATA_CLEAR,
    key: payload.key,
  };
};

let executeDataConfig = {
  VERIFICATION_EXECUTE: {
    url: `admin/partner/verify`
  },
  PUSHNOTIFICATION: {
    url: `admin/notification`
  }

};

export const executeData =  (payload) => {
  let url = executeDataConfig[payload.key].url;
  let headers = {
    'Content-Type': 'application/json',
  };


  if (payload.headers) {
    headers = { ...payload.headers, ...headers };
  }

  return async(dispatch) => {
 
    dispatch(
      executeDataInitiate({
        key: payload.key,
        page: payload.req && payload.req.page ? payload.req.page : false,
      })
    );
    if (payload.token) {
      const access_token =  await getToken();
      headers['Authorization'] = `Bearer ${access_token}`;
    } 
    if (payload.method === 'POST') {
      apiInstance
        .post(
          url,
          {
            ...payload.req,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          dispatch(
            executeDataSuccess({
              key: payload.key,
              data: response.data,
            })
          );
        })
        .catch((error) => {
          dispatch(
            executeDataFailure({
              key: payload.key,
              error,
            })
          );
        });
    } else if (payload.method === 'GET') {
      let qp = '';

      if (payload.req) {
        Object.keys(payload.req).map((key) => {
          if (qp.length > 0) {
            qp += '&';
          }
          qp += `${key}=${payload.req[key]}`;
          return null;
        });
        url = `${url}?${qp}`;
      }

      apiInstance
        .get(url, {
          headers: headers,
        })
        .then((response) => {
          dispatch(
            executeDataSuccess({
              key: payload.key,
              data: response.data,
            })
          );
        })

        .catch((error) => {
          dispatch(
            executeDataFailure({
              key: payload.key,
              error,
            })
          );
        });
    }
  };
};

const executeDataInitiate = (data) => {
  return {
    type: EXECUTE_DATA_INITIATE,
    data,
  };
};

const executeDataSuccess = (data) => {
  return {
    type: EXECUTE_DATA_SUCCESS,
    data: data,
  };
};

const executeDataFailure = (err) => {
  return {
    type: EXECUTE_DATA_FAILED,
    data: err,
  };
};
