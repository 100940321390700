import React from "react";
import i18n from "i18n-js";
import { useSnackbar } from "notistack";
import {useHistory} from "react-router-dom";
import firebase from "firebase/app";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/styles";
import LogoFull from "../../assets/svg/logo_full.svg";
import LogoFullWhite from "../../assets/svg/logo_full_white.svg";
import TextArea from "../../components/textarea/TextArea";
import LoginBg from "../../assets/svg/login_bg.svg";
import Typography from "@material-ui/core/Typography";
import Button from "../../components/button/Button";

const useStyles = makeStyles((theme) => ({
  inputGridItem: {
    width: "100%",
  },
}));

const LoginScreen = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const landscape = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const potrait = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const [email, setEmail] = React.useState(null);

  const [password, setPassword] = React.useState(null);
  const formRef = React.useRef();

  const handleSubmit = () => {
    if (!formRef.current.reportValidity()) {
      return;
    }
    if (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(email).toLowerCase())) {
        enqueueSnackbar(i18n.t("invalid_email"), { variant: "error" });
        return;
      }
    }
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        if(user) {
            history.replace('/')
        }
        // ...
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        return;
      });
  };

  return (
    <Container disableGutters>
      {landscape ? (
        <img
          style={{
            height: theme.spacing(5),
            position: "absolute",
            top: theme.spacing(6),
            left: theme.spacing(6),
          }}
          src={LogoFull}
          alt="logo"
        />
      ) : (
        <img
          style={{
            height: theme.spacing(3),
            position: "absolute",
            top: theme.spacing(3),
            left: theme.spacing(3),
          }}
          src={LogoFullWhite}
          alt="logo"
        />
      )}
      <Grid
        container
        direction={potrait ? "column" : "row-reverse"}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12} sm={6}>
          <Box
            bgcolor={theme.palette.primary.light}
            height={potrait ? "100%" : "100vh"}
            width={potrait ? "100vw" : "50vw"}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <img src={LoginBg} alt="login_bg" style={{ width: "60%" }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            bgcolor={theme.palette.secondary.light}
            height={potrait ? "100%" : "100vh"}
            width={potrait ? "100vw" : "100%"}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box width="80%" margin={4}>
              <Box paddingBottom={4}>
                <Typography gutterBottom variant="h2" color="textPrimary">
                  {i18n.t("login")}
                </Typography>
                <Typography gutterBottom variant="body2" color="textSecondary">
                  {i18n.t("login_label")}
                </Typography>
              </Box>
              <form ref={formRef}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justify="center"
                  spacing={3}
                >
                  <Grid item className={classes.inputGridItem}>
                    <Typography variant="body2" color="textSecondary">
                      {i18n.t("enter_email_label")}
                    </Typography>
                    <TextArea
                      fullWidth
                      color="primary"
                      autoFocus
                      required
                      placeholder={i18n.t("enter_email")}
                      type="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item className={classes.inputGridItem}>
                    <Typography variant="body2" color="textSecondary">
                      {i18n.t("enter_password_label")}
                    </Typography>
                    <TextArea
                      fullWidth
                      color="primary"
                      autoFocus
                      required
                      placeholder={i18n.t("enter_password")}
                      type="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item className={classes.inputGridItem}>
                    <Button
                      variant="contained"
                      width="100%"
                      onClick={handleSubmit}
                      color="primary"
                      disableElevation
                    >
                      {i18n.t("login")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoginScreen;
