import {
  FETCH_DATA_INITIATE,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILED,
  FETCH_DATA_CLEAR,
} from '../actionType';
const initialState = {};

const fetchDataInitiate = (state, action) => {
  let updatedState = Object.assign({}, state[action.data.key]);
 if(action.data.subKey) {
  if (action.data.page) {
    updatedState[action.data.subKey] = Object.assign({}, updatedState[action.data.subKey]);;
    updatedState[action.data.subKey][action.data.page] = {};
    updatedState[action.data.subKey][action.data.page].isInitiated = true; 
  } else {
    updatedState[action.data.subKey] = Object.assign({}, updatedState[action.data.subKey]);;
    updatedState[action.data.subKey].isInitiated = true;
   
  }
 } else {
  if (action.data.page) {
    updatedState[action.data.page] = {};
    updatedState[action.data.page] = {
      isInitiated: true,
    };
  } else {
    updatedState = {
      isInitiated: true,
    };
  }
}

  return { ...state, [action.data.key]: updatedState };
};

const fetchDataSuccess = (state, action) => {
  let updatedState = Object.assign({}, state[action.data.key]);
  if(action.data.subKey) {
    if (action.data.data.page) {
      updatedState[action.data.subKey] = Object.assign({}, updatedState[action.data.subKey]);;
      updatedState[action.data.subKey][action.data.data.page] = {};
      updatedState[action.data.subKey][action.data.data.page].isInitiated = true;
      updatedState[action.data.subKey][action.data.data.page].isDone = true;
      updatedState[action.data.subKey][action.data.data.page].data = action.data.data;
    } else {
      updatedState[action.data.subKey] = Object.assign({}, updatedState[action.data.subKey]);
      updatedState[action.data.subKey].isInitiated = true;
      updatedState[action.data.subKey].isDone = true;
      updatedState[action.data.subKey].data = action.data.data;
    }
   } else {
  if (action.data.data.page) {
    updatedState[action.data.data.page] = {};
    updatedState[action.data.data.page].isInitiated = true;
    updatedState[action.data.data.page].isDone = true;
    updatedState[action.data.data.page].data = action.data.data;
  } else {
    updatedState.isInitiated = true;
    updatedState.isDone = true;
    updatedState.data = action.data.data;
  }
}

  return { ...state, [action.data.key]: updatedState };
};

const fetchDataFailure = (state, action) => {
  let updatedState = Object.assign({}, state[action.data.key]);
  if(action.data.subKey) {
    if (action.data.data.page) {
      updatedState[action.data.subKey] = Object.assign({}, updatedState[action.data.subKey]);;
      updatedState[action.data.subKey][action.data.data.page] = {};
      updatedState[action.data.subKey][action.data.data.page].isInitiated = true;
      updatedState[action.data.subKey][action.data.data.page].isError = true;
      updatedState[action.data.subKey][action.data.data.page].error = action.data.error;
    } else {
      updatedState[action.data.subKey] = Object.assign({}, updatedState[action.data.subKey]);
      updatedState[action.data.subKey].isInitiated = true;
      updatedState[action.data.subKey].isError = true;
      updatedState[action.data.subKey].error = action.data.error;
    }
   }
   else{
  if (action.data.page) {
    updatedState[action.data.data.page] = {};
    updatedState[action.data.data.page].isInitiated = true;
    updatedState[action.data.data.page].isDone = true;
    updatedState[action.data.data.page].isError = true;
  } else {
    updatedState.isInitiated = true;
    updatedState.isError = true;
    updatedState.error = action.data.error;
  }
}
  return { ...state, [action.data.key]: updatedState };
};
const clear = (state, action) => {
  if (action.key.page) {
    return { ...state, [action.key.page]: {} };
  }
  return { ...state, [action.key]: {} };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_INITIATE:
      return fetchDataInitiate(state, action);
    case FETCH_DATA_SUCCESS:
      return fetchDataSuccess(state, action);
    case FETCH_DATA_FAILED:
      return fetchDataFailure(state, action);
    case FETCH_DATA_CLEAR:
      return clear(state, action);
    default:
      return state;
  }
};

export default reducer;
