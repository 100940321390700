import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import tinycolor from "tinycolor2";

let theme = createMuiTheme({
  typography: {
    
    fontSize: 12,
    h1: {
      fontWeight: '700',
      fontSize: 32
    },
    h2: {
      fontWeight: '600',
      fontSize: 36
    },
    h3: {
      fontWeight: '600',
      fontSize: 24
    },
    subtitle1: {
      fontWeight: '500',
      fontSize: 24,
      lineHeight: 1.4
    },
    subtitle2: {
      fontWeight: '500',
      fontSize: 20
    },
    body1: {
      fontWeight: '400',
      fontSize: 18
    },
    body2: {
      fontWeight: '500',
      fontSize: 14
    },
    button:{
      fontWeight: '700',
      fontSize: 16
    }
  },
  palette: {
    focused: {
      dark: tinycolor("#ee6554").darken(6).toString(),
      main: tinycolor("#ee6554").toString(),
      light: tinycolor("#ee6554").lighten(6).toString(),
      contrastText: tinycolor("#fff").toString(),
    },
    primary: {
      dark: tinycolor("#0f8971").toString(),
      main: tinycolor("#0f8971").lighten(6).toString(),
      light: tinycolor("#0f8971").lighten(15).toString(),
      contrastText: tinycolor("#fff").toString(),
    },
    tertiary: {
      dark: tinycolor("#279680").darken(6).toString(),
      main: tinycolor("#279680").toString(),
      light: tinycolor("#279680").lighten(7.5).toString(),
      contrastText: tinycolor("#000").toString(),
    },
    dark: {
      dark: tinycolor("#0E0E2C").darken(6).toString(),
      main: tinycolor("#0E0E2C").toString(),
      light: tinycolor("#0E0E2C").lighten(6).toString(),
      contrastText: tinycolor("#fff").toString()
    },
    success: {
      dark: tinycolor("#31D0AA").darken(6).toString(),
      main: tinycolor("#31D0AA").toString(),
      light: tinycolor("#31D0AA").lighten(6).toString(),
      contrastText: tinycolor("black").toString()
    },
    accent: {
      dark: tinycolor("#e4e5e9").darken(6).toString(),
      main: tinycolor("#e4e5e9").toString(),
      light: tinycolor("#e4e5e9").lighten(6).toString(),
      contrastText: tinycolor("#000").toString()
    },
    secondary: {
      dark: tinycolor("#FAFCFE").darken(6).toString(),
      main: tinycolor("#FAFCFE").toString(),
      light: tinycolor("#FAFCFE").lighten(6).toString(),
      contrastText: tinycolor("#4B4DED").lighten(6).toString()
    },
    text: {
      primary: tinycolor("#4D4D4D").toString(),
      secondary: tinycolor("#bdbdbd").toString(),
      disabled: tinycolor("#e4e5e9").toString(),
      caption: tinycolor("#D3D3D3").toString(),
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
});
theme = responsiveFontSizes(theme)

export default theme;
