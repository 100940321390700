import axios from 'axios';
import { firebaseConfigs } from '../../firebaseConfig';
const ProductionApiBase = {
    // baseUrl: "https://us-central1-nestease-admin-a0904.cloudfunctions.net/adminFunctions",
    baseUrl:
      firebaseConfigs()?.projectId === "nestease-dev-admin"
        ? "https://us-central1-nestease-dev-admin.cloudfunctions.net/AdminFunctions"
        : "https://us-central1-nestease-admin-a0904.cloudfunctions.net/AdminFunctions",
      // firebaseConfigs()?.projectId === "nestease-dev-admin"
      //   ? "http://127.0.0.1:5001/nestease-dev-admin/us-central1/AdminFunctions"
      //   : "http://127.0.0.1:5001/nestease-admin-a0904/us-central1/AdminFunctions",
  // baseUrl:"http://127.0.0.1:5001/nestease-admin-a0904/us-central1/AdminFunctions"
  };

// const developmentApiBase = {
//     baseUrl: "https://us-central1-nestease-admin-a0904.cloudfunctions.net/adminFunctions",
// }
// "http://localhost:5001/nestease-66d12/us-central1/widgets/",
// https://a8c10491a521.ngrok.io

////////////////Select Api Base////////////////////

const apiBase = ProductionApiBase;

///////////////////////////////////////////////////

export const apiInstance = axios.create({
    baseURL: apiBase.baseUrl
});

export const axiosInstance = axios.create({});
