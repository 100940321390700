export const SAVE_DATA_ASYNC = 'SAVE_DATA_ASYNC';
export const SAVE_DATA_ASYNC_LANGUAGE = 'SAVE_DATA_ASYNC_LANGUAGE';
export const CLEAR_DATA_ASYNC = 'CLEAR_DATA_ASYNC';
export const LOGOUT = 'LOGOUT';

export const FETCH_DATA_INITIATE = 'FETCH_DATA_INITIATE';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILED = 'FETCH_DATA_FAILED';
export const FETCH_DATA_CLEAR = 'FETCH_DATA_CLEAR';

export const EXECUTE_DATA_INITIATE = 'EXECUTE_DATA_INITIATE';
export const EXECUTE_DATA_SUCCESS = 'EXECUTE_DATA_SUCCESS';
export const EXECUTE_DATA_FAILED = 'EXECUTE_DATA_FAILED';
export const EXECUTE_DATA_CLEAR = 'EXECUTE_DATA_CLEAR'