import React from "react";
import Box from "@material-ui/core/Box";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import * as Types from '../constants/types/types';
import Drawer from "../components/drawer";
import Customer from "./customer";
import Partner from "./partner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: '100%'
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    width: '100%'
  },
  content: {
    flexGrow: 1,
  },
}));

const AdminIndex = () => {
  const classes = useStyles();
  const { screen } = useParams();

  return (
    <Box className={classes.root}>
      <Drawer />
      <main className={classes.content}>
        <div className={classes.toolbar} />
       {screen === Types.CUSTOMERS && <Customer />}
       {screen === Types.PARTNERS && <Partner />}
      </main>
    </Box>
  );
};

export default AdminIndex;
