import React from "react";
import clsx from "clsx";
import firebase from "firebase/app";
import * as Types from "../../constants/types/types";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles /*useTheme*/ } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Typography from '@material-ui/core/Typography';
import AccountCircle from "@material-ui/icons/AccountCircle";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from "@material-ui/icons/Mail";
import PeopleIcon from "@material-ui/icons/People";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import PieChartIcon from "@material-ui/icons/PieChart";
import SettingsIcon from "@material-ui/icons/Settings";
import LogoWhite from "../../assets/svg/logo_white.svg";
import LogoWhiteFull from "../../assets/svg/logo_full_white.svg";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    marginLeft: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.primary.main,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolBarMain: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  listItem: {
    height: 60,
  },
  listContainer: {
    padding: 0,
  },
  listItemText: {
    textTransform: "capitalize",
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const history = useHistory();
  const { screen } = useParams();
  // const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [activeList] = React.useState(
    screen === Types.INSIGHTS
      ? 0
      : screen === Types.CUSTOMERS
      ? 1
      : screen === Types.PARTNERS
      ? 2
      : screen === Types.SETTINGS
      ? 3
      : 5
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.replace("/login");
      });
  };

  const handleListClick = (text, idx) => {
    // setActiveList(idx);
    history.push(`/dashboard/${text.toLowerCase()}`, { index: idx });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolBarMain}>
          {!open && <img src={LogoWhite} style={{ height: 36 }} alt="logo" />}
          <Grid container direction="row">
            <Grid item xs={6}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <Box flexDirection="row-reverse" display="flex">
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={openMenu}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Box>
            </Grid>
          </Grid>

          {/* <Typography variant="h6" noWrap>
            Mini variant drawer
          </Typography> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <img src={LogoWhiteFull} style={{ height: 25 }} alt="logo_full" />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon color="secondary" />
          </IconButton>
        </div>
        <Divider />
        <List className={classes.listContainer}>
          {[
            Types.INSIGHTS,
            Types.CUSTOMERS,
            Types.PARTNERS,
            Types.SETTINGS,
          ].map((text, index) => (
            <Box
              key={text}
              flexDirection="column"
              display="flex"
              alignItems="center"
            >
              <ListItem
                button
                className={classes.listItem}
                onClick={() => handleListClick(text, index)}
              >
                <ListItemIcon>
                  {text === Types.INSIGHTS && (
                    <PieChartIcon
                      color={activeList === index ? "primary" : "disabled"}
                    />
                  )}
                  {text === Types.CUSTOMERS && (
                    <PeopleIcon
                      color={activeList === index ? "primary" : "disabled"}
                    />
                  )}
                  {text === Types.PARTNERS && (
                    <GroupWorkIcon
                      color={activeList === index ? "primary" : "disabled"}
                    />
                  )}
                  {text === Types.SETTINGS && (
                    <SettingsIcon
                      color={activeList === index ? "primary" : "disabled"}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={text}
                  primaryTypographyProps={{
                    color: activeList === index ? "primary" : "textSecondary",
                    variant: "body2",
                  }}
                />
                <ChevronRightIcon
                  color={activeList === index ? "primary" : "disabled"}
                />
              </ListItem>
              <Divider variant="fullWidth" light />
            </Box>
          ))}
        </List>

        {/* <List>
          {['FeedBacks', 'Settings'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 !== 0 ? <SettingsIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>
    </div>
  );
}
