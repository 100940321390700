import { useEffect, useState } from "react";
import i18n from "i18n-js";

export const useLanguage = () => {
  const [language, setLanguage] = useState(null);
  const setNewLanguage = async (languageValue) => {
    i18n.locale = languageValue;
    localStorage.setItem("ln", languageValue);
  };

  const setLastLanguage = async () => {
    let language_preference = await localStorage.getItem("ln");
    if (language_preference) {
      setLanguage(language_preference);
    }
  };

  useEffect(() => {
    setLastLanguage();
  }, []);

  return [language, setNewLanguage];
};
