import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import at from "v-at";
import i18n from "i18n-js";
import { Typography, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PersonIcon from "@material-ui/icons/Person";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import SettingsIcon from "@material-ui/icons/Settings";
import ReceiptIcon from "@material-ui/icons/Receipt";
import {
  DEFAULT,
  SETTINGS,
  PAYMENTS,
  VERIFICATION,
  NEW,
  VERIFIED,
  REQUESTED,
  PARTNERS_PROFILE,
} from "../../constants/types/types";
import Button from "../button/Button";

const PartnerProfile = (props) => {
  const useStyles = makeStyles((theme) => ({}));
  const classes = useStyles();
  const theme = useTheme();
  const [view, setView] = React.useState(VERIFICATION);
  const [isReject, setIsReject] = React.useState(false);
  const [rejectionNote, setRejectionNote] = React.useState("");
  const checkCondition = () => {
    if (rejectionNote.trim() !== "") {
      props.rejectClick(rejectionNote);
      setIsReject(false);
    } else {
      props.rejectClick("");
      setIsReject(false);
    }
  };
  console.log('at(props, "partner.has_documents") :>> ', at(props, "partner"));
  const renderVerification = () => {
    return (
      <Box width="100%" p={2}>
        <Box
          id="banner_label"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          {!at(props, "partner.has_documents") &&
          !at(props, "partner.verification_status") ? (
            <Box
              id="banner_request_documents"
              alignItems="center"
              justifyContent="space-between"
              pl={2}
              pr={2}
              display="flex"
              width={"100%"}
              height={80}
              bgcolor={theme.palette.error.light}
              borderRadius={16}
            >
              <Typography variant="body2" color="secondary">
                {i18n.t("banner_request_documents")}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={props.requestDocuments}
                disableElevation
              >
                {i18n.t("request")}
              </Button>
            </Box>
          ) : !at(props, "partner.has_documents") &&
            at(props, "partner.verification_status") === REQUESTED ? (
            <Box
              id="banner_requested"
              alignItems="center"
              justifyContent="space-between"
              pl={2}
              pr={2}
              display="flex"
              width={"100%"}
              height={80}
              bgcolor={theme.palette.warning.light}
              borderRadius={16}
            >
              <Typography variant="body2" color="secondary">
                {i18n.t("banner_requested")}
              </Typography>
            </Box>
          ) : at(props, "partner.has_documents") &&
            at(props, "partner.verification_status") === NEW ? (
            <Box
              id="verify_banner"
              alignItems="center"
              justifyContent="space-between"
              pl={2}
              pr={2}
              display="flex"
              width={"100%"}
              height={80}
              bgcolor={theme.palette.info.light}
              borderRadius={16}
            >
              <Typography variant="body2" color="secondary">
                {i18n.t("verify_banner")}
              </Typography>
            </Box>
          ) : at(props, "partner.has_documents") &&
            at(props, "partner.verification_status") === VERIFIED ? (
            <Box
              id="completed_banner"
              alignItems="center"
              justifyContent="space-between"
              pl={2}
              pr={2}
              display="flex"
              width={"100%"}
              height={80}
              bgcolor={theme.palette.success.light}
              borderRadius={16}
            >
              <Typography variant="body2" color="secondary">
                {i18n.t("completed_banner")}
              </Typography>
            </Box>
          ) : (
            <Box
              id="rejected_banner"
              alignItems="center"
              justifyContent="space-between"
              pl={2}
              pr={2}
              display="flex"
              width={"100%"}
              height={80}
              bgcolor={theme.palette.error.main}
              borderRadius={16}
            >
              <Typography variant="body2" color="secondary">
                {i18n.t("rejected_banner")}
              </Typography>
            </Box>
          )}
        </Box>
        {at(props, "partner.has_documents") &&
        at(props, "partner.verification_status") ? (
          // at(props, "partner.verification_status") === NEW) || (at(props, "partner.has_documents") &&
          // at(props, "partner.verification_status") === VERIFIED &&
          <Box id="document_verify">
            <Box id="id_docs" mt={2} display="flex" flexDirection="row">
              <Box id="id_front" ml={2}>
                <Typography variant="subtitle2">
                  {i18n.t("id_front_label")}
                </Typography>
                <img
                  src={at(props, "partner.id_front_url")}
                  style={{ height: 400 }}
                  onClick={() => window.open(at(props, "partner.id_front_url"))}
                  alt="id_front"
                />
              </Box>
              <Box id="id_back" ml={2}>
                <Typography variant="subtitle2">
                  {i18n.t("id_back_label")}
                </Typography>
                <img
                  src={at(props, "partner.id_back_url")}
                  style={{ height: 400 }}
                  onClick={() => window.open(at(props, "partner.id_back_url"))}
                  alt="id_front"
                />
              </Box>
              {at(props, "partner.document_url") ? (
                <Box id="document" ml={2}>
                  <Typography variant="subtitle2">
                    {i18n.t("insurance")}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      window.open(at(props, "partner.document_url"))
                    }
                    disableElevation
                  >
                    {i18n.t("view")}
                  </Button>
                </Box>
              ) : null}
            </Box>
          </Box>
        ) : null}
        {isReject && (
          <div>
            <h3>Rejection note</h3>
            <textarea
              value={rejectionNote}
              onChange={(evant) => {
                setRejectionNote(evant.target.value);
              }}
              placeholder="Type something..."
              rows={5}
              cols={30}
            />
          </div>
        )}
        {at(props, "partner.has_documents") &&
        at(props, "partner.verification_status") === NEW ? (
          <Box id="action" display="flex" flexDirection="row" mt={2}>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  isReject ? checkCondition() : props.verifyClick()
                }
                disableElevation
              >
                {isReject ? i18n.t("conform") : i18n.t("verify")}
              </Button>
            </Box>
            <Box ml={2}>
              <Button
                variant="outlined"
                color="primary"
                // onClick={() => props.rejectClick()}
                onClick={() =>
                  isReject ? setIsReject(false) : setIsReject(true)
                }
                disableElevation
              >
                {isReject ? i18n.t("cancel") : i18n.t("reject")}
              </Button>
            </Box>
          </Box>
        ) : (
          <div>
            {at(props, "partner.rejection_note") && (
              <div>
                <h3>Rejection note</h3>
                <p>{at(props, "partner.rejection_note")}</p>
              </div>
            )}
          </div>
        )}
      </Box>
    );
  };

  const renderPartnersProfile = () => {
    return (
      <Box width="100%" height="100%" p={10} style={{ alignContent: "center" }}>
        <Box
          padding={2}
          width={"600px"}
          style={{
            justifySelf: "center",
            borderRadius: 10,
            display: "flex",
            flexDirection: "row",
            boxShadow: "0px 0px 15px #9E9E9E",
          }}
          bgcolor={"#fff"}
        >
          <Box style={{ width: "55%" }}>
            <img
              src={at(props, "partner.avatar")}
              style={{ width: "100%", height: "auto", borderRadius: 10 }}
            />
          </Box>
          <Box paddingLeft={4} style={{ width: "45%" }}>
            <h1 style={{ lineHeight: "16px" }}>
              {at(props, "partner.displayName")}
            </h1>
            <h4 style={{ lineHeight: "10px" }}>{at(props, "partner.type")}</h4>
            <h5 style={{ lineHeight: "8px", marginTop: "20px" }}>
              Creation time :- {at(props, "partner.creationTime")}
            </h5>
            <h5 style={{ lineHeight: "8px" }}>
              Number :- {at(props, "partner.phoneNumber")}
            </h5>
            <h5 style={{ lineHeight: "8px" }}>
              Email :- {at(props, "partner.email")}
            </h5>
            {at(props, "partner.address") && (
              <h5>
                Address :- {at(props, "partner.address.name")?at(props, "partner.address.name"):at(props, "partner.address.formattedAddress")}
              </h5>
            )}
            {/* <h5 style={{lineHeight:'8px'}}>creation time :- {at(props, "partner.creationTime")}</h5>
            <h5 style={{lineHeight:'8px'}}>creation time :- {at(props, "partner.creationTime")}</h5>
            <h5 style={{lineHeight:'8px'}}>creation time :- {at(props, "partner.creationTime")}</h5>
            <h5 style={{lineHeight:'8px'}}>creation time :- {at(props, "partner.creationTime")}</h5>
            <h5 style={{lineHeight:'8px'}}>creation time :- {at(props, "partner.creationTime")}</h5>
            <h5 style={{lineHeight:'8px'}}>creation time :- {at(props, "partner.creationTime")}</h5>
            <h5 style={{lineHeight:'8px'}}>creation time :- {at(props, "partner.creationTime")}</h5> */}
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <Box
      id="main"
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
    >
      <Box
        id="header"
        width="100%"
        height={60}
        bgcolor={theme.palette.secondary.light}
        boxShadow={`0 4px 2px -2px ${theme.palette.text.disabled}`}
      >
        <Box m={2} display="flex" flexDirection="row" alignItems="center">
          <IconButton onClick={props.onBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">{i18n.t("partner_profile")}</Typography>
        </Box>
      </Box>
      <Box
        id="main_content"
        mt={2}
        bgcolor={theme.palette.secondary.light}
        flexGrow={1}
        width="100%"
        flexDirection="row"
        height="75vh"
        display="flex"
      >
        <Box
          id="side_nav"
          width={250}
          height={"100%"}
          border={1}
          borderLeft={0}
          borderTop={0}
          borderBottom={0}
          borderColor={theme.palette.text.disabled}
        >
          <Box>
            <Box
              height={48}
              p={2}
              border={1}
              borderLeft={0}
              borderTop={0}
              borderRight={0}
              borderColor={theme.palette.text.disabled}
              display="flex"
              alignItems="center"
              onClick={() => {
                setView(PARTNERS_PROFILE);
              }}
            >
              <IconButton>
                <PersonIcon
                  color={view === PARTNERS_PROFILE ? "primary" : "disabled"}
                />
              </IconButton>
              <Typography
                color={view === PARTNERS_PROFILE ? "primary" : "textSecondary"}
                variant="body2"
              >
                {i18n.t("profile")}
              </Typography>
            </Box>
            <Box
              height={48}
              p={2}
              border={1}
              borderLeft={0}
              borderTop={0}
              borderRight={0}
              borderColor={theme.palette.text.disabled}
              display="flex"
              alignItems="center"
              onClick={() => {
                setView(VERIFICATION);
              }}
            >
              <IconButton>
                <VerifiedUserIcon
                  color={view === VERIFICATION ? "primary" : "disabled"}
                />
              </IconButton>
              <Typography
                color={view === VERIFICATION ? "primary" : "textSecondary"}
                variant="body2"
              >
                {i18n.t("verification")}
              </Typography>
            </Box>
            <Box
              height={48}
              p={2}
              border={1}
              borderLeft={0}
              borderTop={0}
              borderRight={0}
              borderColor={theme.palette.text.disabled}
              display="flex"
              alignItems="center"
            >
              <IconButton>
                <ReceiptIcon
                  color={view === PAYMENTS ? "primary" : "disabled"}
                />
              </IconButton>
              <Typography
                color={view === PAYMENTS ? "primary" : "textSecondary"}
                variant="body2"
              >
                {i18n.t("payments")}
              </Typography>
            </Box>
            <Box
              height={48}
              p={2}
              border={1}
              borderLeft={0}
              borderTop={0}
              borderRight={0}
              borderColor={theme.palette.text.disabled}
              display="flex"
              alignItems="center"
            >
              <IconButton>
                <SettingsIcon
                  color={view === SETTINGS ? "primary" : "disabled"}
                />
              </IconButton>
              <Typography
                color={view === SETTINGS ? "primary" : "textSecondary"}
                variant="body2"
              >
                {i18n.t("settings")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box id="content" width="100%">
          {view === VERIFICATION && renderVerification()}
          {view === PARTNERS_PROFILE && renderPartnersProfile()}
        </Box>
      </Box>
    </Box>
  );
};

export default PartnerProfile;
