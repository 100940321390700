import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function CircularIndeterminate(props) {
  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      height: props.position ? '100%' : window.innerHeight,
      width: props.position ? '100%' : window.innerWidth,
      justifyContent: 'center',
      position: props.position ? props.position : 'absolute',
     
      flexDirection: 'column',
      zIndex: 10,
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress color="primary" />
    </div>
  );
}
