import firebase from "firebase/app";
import { firebaseConfigs } from "../../firebaseConfig";


const firebaseConfig = firebaseConfigs()

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}


export async function getCurrentUser() {
 
  const user = await firebase.auth().currentUser;
  if (user) {
   
    return user;
  }
  return false;
}
export async function getCurrentUserId() {
 
  const user = await firebase.auth().currentUser;
  if (user) {
   
    return user.uid;
  }
  return false;
}

export async function getToken() {
  const user = await firebase.auth().currentUser;
  if (user) {
    const token = await user.getIdToken(true);
    return token;
  }
  return null;
}


export async function updateProfile(data) {
  const user = await getCurrentUser();

  user
    .updateProfile(data)
    .then(function () {
      return true;
    })
    .catch(function (error) {
      throw error;
    });
}

export async function deleteUser(data) {
  const user = await getCurrentUser();

  user
    .delete()
    .then(function () {
      return true;
    })
    .catch(function (error) {
      throw error;
    });
}
