export const firebaseConfigs = () => {
  const isDev = false;
  const firebaseConfigProd = {
    apiKey: "AIzaSyBgxlkl_oh-dWBbSZU_YFLZXsoVvU-6np8",
    authDomain: "nestease-admin-a0904.firebaseapp.com",
    projectId: "nestease-admin-a0904",
    storageBucket: "nestease-admin-a0904.appspot.com",
    messagingSenderId: "367194494050",
    appId: "1:367194494050:web:2b2096b0bd931bca552d3b",
    measurementId: "G-XX8VQ7VL1C",
  };
  const firebaseConfigDev = {
    apiKey: "AIzaSyBorTqav0DMng_NkrtXqkFZGZ0TpzE2Rqg",
    authDomain: "nestease-dev-admin.firebaseapp.com",
    projectId: "nestease-dev-admin",
    storageBucket: "nestease-dev-admin.appspot.com",
    messagingSenderId: "897318404225",
    appId: "1:897318404225:web:fa97931be18236be624d80"
  };
  return isDev?firebaseConfigDev:firebaseConfigProd
};
