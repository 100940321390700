import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  inputInvert: {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
  },
  input: {
    color: theme.palette.text.primary,
    borderColor: theme.palette.primary.main,
    fontSize: theme.spacing(1.5)
  },
}));

const TextAreaCustom = (props) => {
  const classes = useStyles();

  return (
    <OutlinedInput
      className={props.color === 'secondary' ? classes.inputInvert : classes.input}
      {...props}
      onChange={props.onChange}
      variant="outlined"
    />
  );
};

TextAreaCustom.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "secondary"]),
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
};

export default TextAreaCustom;
