// Screens
export const INSIGHTS ='insights';
export const CUSTOMERS ='customers';
export const PARTNERS ='partners';
export const SETTINGS ='settings';
export const PAYMENTS ='payments';
export const VERIFICATION ='verification';
export const PARTNERS_PROFILE ='partnersProfile';


// Sub Screens
export const DEFAULT ='DEFAULT';
export const DETAILED = 'DETAILED';

// Api methods
export const GET = "GET";
export const POST = "POST";

//dispatch events
export const IS_INITIATED = "isInitiated";
export const IS_DONE = "isDone";
export const IS_ERROR = "isError";

// API STATES

// FETCH

export const FETCH_DATA_CUSTOMER = 'FETCH_DATA_CUSTOMER';
export const FETCH_DATA_PARTNER = 'FETCH_DATA_PARTNER';
export const FETCH_PARTNER = 'FETCH_PARTNER';

// EXECUTE
export const VERIFICATION_EXECUTE = 'VERIFICATION_EXECUTE';


//status

export const NEW = 'NEW';
export const COMPLETED = 'COMPLETED';
export const FAILED = 'FAILED';
export const REQUEST = 'REQUEST';
export const VERIFY = 'VERIFY';
export const REJECT = 'REJECT';
export const REQUESTED = 'REQUESTED';
export const VERIFIED = 'VERIFIED';